.description {
  color: black;
}
.table {
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.text-capitalize {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.container-content {
  border-radius: 10px;
  overflow: hidden;
  width: 400px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.347);
  /* padding: 1rem; */
  /* gap: 0 1rem; */
  /* background-color: antiquewhite */
}
.image {
  width: 9%;
  height: 80%;
  margin-left: 5px;
}
.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.description {
  width: 65%;
  padding: 1rem;
}
.container-content svg {
  padding: 1rem;
  /* height: 40px !important; */
  font-size: 1.5rem;
}
.btn-primary {
  margin-top: 2rem;
  padding: 10px 20px;
  cursor: pointer;
}