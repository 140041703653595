.Bechofy-btn {
  color: #fff;
  background-color: #777f80;
  border-color: #777f80;
}

.Bechofy-text {
  color: #f2f9fa;
}

.Bechofy-bg-soft-pink {
  background-color: #777f80;
}

.Bechofy-muted-text {
  color: #777f80;
}

body[data-layout="horizontal"] .container-fluid,
body[data-layout="horizontal"] .container-sm,
body[data-layout="horizontal"] .container-md,
body[data-layout="horizontal"] .container-lg,
body[data-layout="horizontal"] .container-xl,
body[data-layout="horizontal"] .navbar-header {
  max-width: 100%;
}

.Bechofy-avatar-title {
  align-items: center;
  background-color: #777f80;
  color: #fff;
  display: flex;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #777f80;
}

a.dropdown-item.dropdown-toggle.arrow-none:hover {
  color: #777f80;
}

a.nav-link.dropdown-toggle.arrow-none.active {
  color: #777f80 !important;
}

.topnav .navbar-nav .nav-item .nav-link.active {
  /* color: #556ee6; */
  color: #777f80 !important;
}

.topnav .navbar-nav .nav-item .nav-link:hover {
  /* color: #556ee6; */
  color: #777f80 !important;
}

a.dropdown-item:focus {
  color: #777f80;
}

a.dropdown-item.active {
  color: #777f80;
}

.topnav .navbar-nav .dropdown-item.active,
.topnav .navbar-nav .dropdown-item:hover {
  color: #777f80;
}

.topnav .navbar-nav .dropdown.active>a {
  color: #777f80;
  background-color: transparent;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  border-radius: 0.3rem;
  background-color: #777f80;
  color: #fff;
}

.react-datepicker__header {
  text-align: center;
  background-color: #777f80;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
}

.twitter-bs-wizard .twitter-bs-wizard-nav {
  padding: 4px;
  background-color: #f7c4d3;
}

.twitter-bs-wizard .twitter-bs-wizard-nav .step-number {
  display: inline-block;
  width: 38px;
  height: 38px;
  line-height: 34px;
  border: 2px solid #777f80;
  color: #777f80;
  text-align: center;
  border-radius: 50%;
}

.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link.active .step-number {
  background-color: #777f80;
  color: #fff;
}

.custom-file-upload {
  border-radius: 5%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;
  /* background: linear-gradient(270deg, #3fa1a9, #79f1a4); */
}

.custom-file-upload-blog {
  border-radius: 5%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;
  height: 300px;
  width: 1382px !important;
  /* background: linear-gradient(270deg, #3fa1a9, #79f1a4); */
}

.custom-file-upload-image-blog {
  border-radius: 5%;
  position: relative;
  cursor: pointer;
  height: 300px !important;
  width: 1372px !important;
  /* background: linear-gradient(270deg, #3fa1a9, #79f1a4); */
}

.img-wrap-blog {
  position: relative;
  width: 1372px;
  height: 300px !important;
  overflow: hidden;
  /* border-radius: 50%; */
}

.img-wrap {
  position: relative;
  width: 120px;
  height: auto;
  overflow: hidden;
  /* border-radius: 50%; */
}

.img-upload:before {
  font-family: "Font Awesome 5 Free";
  content: "\f093";
  font-size: 90px;
  position: absolute;
  padding-top: 45px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #777f80;
  width: 200px;
  height: 200px;
  /* border-radius: 50%; */
  opacity: 0;
  transition: 0.5s ease;
  background-color: #fff;
}

.carousel-item .img-upload:before {
  display: none;
}

.carousel-item .img-upload input:before {
  font-family: "Font Awesome 5 Free";
  content: "\f093";
  font-size: 90px;
  position: absolute;
  padding-top: 45px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #777f80;
  width: 400px;
  height: 400px;
  /* border-radius: 50%; */
  opacity: 0;
  transition: 0.5s ease;
  background-color: #fff;
}

.img-upload:hover:before {
  opacity: 1;
}

.carousel-item:hover input::before {
  opacity: 1;
}

.carousel-item {
  position: relative;
}

.carousel-item input {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20rem 35rem;
  display: none;
  height: 100%;
  width: 100%;
}

.carousel-item input[type="file"] {
  display: none;
}

input[type="file"] {
  display: none;
}

.carousel-item:hover input[type="file"] {
  display: block;
}

.dash {
  height: 105px;
}

.our-product-collection-image-upload {
  position: relative;
}

.our-product-collection-image-upload input[type="file"] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5rem 12rem;
  display: none;
  height: 100%;
  width: 100%;
}

.our-product-collection-image-upload input[type="file"]:before {
  font-family: "Font Awesome 5 Free";
  content: "\f093";
  font-size: 90px;
  position: absolute;
  padding-top: 45px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #777f80;
  width: 70%;
  height: 70%;
  /* border-radius: 50%; */
  opacity: 0;
  transition: 0.5s ease;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.our-product-collection-image-upload:hover input[type="file"]:before {
  opacity: 1;
}

.our-product-collection-image-upload input[type="file"] {
  display: none;
}

.our-product-collection-image-upload:hover input[type="file"] {
  display: block;
}

.category-card-style img {
  height: 350px;
}

.parallax {
  background-size: cover !important;
}

.know-more-btn {
  border: 1px solid;
}

/* table.mdb-dataTable thead, table thead.mdb-dataTable-head {
    cursor: pointer;
    background: #f8f9fa!important;
    /* text-align: center!important; */
/* } */

/*
table.mdb-dataTable thead, table thead.mdb-dataTable-head:nth-of-type(8) {
  background: red;
} */
.table-striped tbody tr {
  background: #fff !important;
  text-align: center !important;
}

/* .btn:hover {
  color: #000 !important;
} */
/* button.btn-rounded.Bechofy-btn.waves-effect.waves-light.text-center.btn.btn-secondary.btn-sm{
  position: relative;
  left: 44px;
} */
/* .max-dashbtn{
  position: relative;
  left: 46px;
} */
.categoryCard {
  border: 1px solid #f8f8fb;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 5px;
  margin-top: 10px;
}

.imagecard {
  margin-top: -13px;
}

.categoryimage {
  height: 150px;
  width: 150px;
  border: 1px solid #f8f8fb;
  padding: 2px;
  margin-bottom: 15px;
}

.categoryimage2 {
  height: 100px;
  width: 100px;
  border: 1px solid #f8f8fb;
  padding: 2px;
  margin-bottom: 13px;
}

.card-row {
  border-bottom: 1px solid #f8f8fb;
}

.deleteicnbtn {
  float: right;
  margin-top: 5px;
  color: #74788d;
}

.categorystatus {
  margin-top: 15px;
}

.categorydesc {
  text-align: justify;
  padding-left: 25px;
}

.subcategoryname {
  color: #74788d;
}

.actionuser {
  position: relative;
  right: 10px;
}

.usercarddata {
  border: 1px solid #f8f8fb;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  -webkit-box-shadow: 2px 2px 5px 1px #e1e1e1;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
}

.usertitle {
  margin-top: 15px;
}

.identi {
  height: 250px;
}

.info {
  height: 530px;
}

.userinfo2 {
  height: 175px;
}

.login-icon-change-pass {
  /* float: right;
  bottom: 32px;
  position: relative;
  right: 9px; */
  float: right;
  bottom: -8px;
  position: relative;
  right: 20px;
}

/* .mdb-datatable.dt-bootstrap4.tablecol{
  text-align: center!important;
} */
.modelcontent2 {
  width: 500px !important;
}

button.react-responsive-modal-closeButton {
  display: none !important;
}

/* .Bechofy-count{
  border: 1px solid grey;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    padding-top: 5px;
    padding-bottom: 5px;
    background: black;
    color: white!important;
    text-align: center;
} */
.Bechofy-count2 {
  border: 1px solid grey;
  /* padding: 6px; */
  /* border-radius: 50%; */
  width: 2rem;
  height: 2rem;
  background: black;
  color: white !important;
  text-align: center;
  width: 55px;
  height: 25px;
  border-radius: 10%;
  margin-left: auto;
  margin-right: auto;
}

.top-selling-banner {
  width: 50%;
  height: 50%;
  margin-right: 2%;
}

.slider-responsive {
  width: 50%;
  height: 100%;
  margin-right: 5px;
  margin-left: 5px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.userdesignation {
  margin-left: 35px;
}

.user_profile {
  margin-top: -45px;
  height: 5.5rem;
  width: 6.5rem;
}

.slick-slider {
  margin-bottom: 20px !important;
}

.best-selling-products {
  padding: 20px 0;
}

.img-container {
  width: 100%;
  /* border: 1px solid #ddd;
  border-radius: 10px; */
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-container img {
  width: 100%;
}

.img-container img {
  width: 50%;
}

.best-products .product-item {
  padding: 10px;
  border: 1px solid #f0f0f0;
  align-content: center;
  align-self: center;
  margin: 5px;
}

.product-desc h4 {
  text-align: center;
  padding-top: 25px;
}

.FilterCard {
  /* text-align: center; */
  color: white;
  float: left;
  margin-left: 10%;
  /* padding-top: 5px; */
}

tr.success td {
  -webkit-print-color-adjust: exact;
  /* background-color: #f7f7f7 !important; */
  background-color: #aeaeaec2 !important;
}

tr.success th {
  -webkit-print-color-adjust: exact;
  /* background-color: #f7f7f7 !important; */
  background: #aeaeaec2 !important;
}

/* body{bacbackground-color: white!important;} */
/* @media only screen and (max-width: 1024px) and (min-width: 360px) { */
/* body{overflow:scroll}} */
tr.danger td {
  background-color: #f7f7f7 !important;
  -webkit-print-color-adjust: exact;
}

.mdb-datatable-filter.flex-row {
  display: none;
}

tbody+thead {
  display: none;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #777f80 !important;
  border-color: #777f80 !important;
}

.Notificatin-image {
  font-weight: 900;
  font-size: 1.1rem;
  text-align: center;
  border: 2px solid #fff;
  border-radius: 100px;
  width: 80px;
  height: 80px;
  padding-bottom: 3px;
  margin-top: -20px;
  /* margin-right: 10px; */
}

.Notification-head {
  margin-top: 0px;
  margin-left: 10px;
}

.notfication-card {
  height: 100px;
}

.notfication-card table {
  margin-top: -6px;
}

/* li.nav-item.dropdown{
  margin-left: -2px!important;
} */
.ProfilesIcon {
  text-align: center;
  margin-left: -5px;
}

.ProductIcons {
  width: 30px;
  float: right;
  margin-top: -5px;
  margin-right: -5px;
}

.productTitle {
  text-align: left;
  margin-left: -15px;
}

.monthlyChart {
  margin-left: -70px;
  margin-top: -30px;
}

.apexcharts-toolbar {
  display: none;
}

.gridinput {
  margin-top: 15px;
}

.inputWithIcon i {
  position: absolute;
  left: 0;
  top: 6px;
  padding: 4px 22px;
  color: #aaa;
  transition: 0.3s;
  float: right;
}

/* .inputWithIcon input[type="text"]:focus + i {
  color: dodgerBlue;
} */
.inputWithIcon.inputIconBg i {
  background-color: #aaa;
  color: #fff;
  padding: 9px 4px;
  border-radius: 4px 0 0 4px;
}

.customerProfile {
  width: 100px;
  height: 100px;
  float: right;
  /* margin-bottom: 12px; */
  margin-right: 10px;
  margin-top: 20px;
  border: 1px solid grey;
  border-radius: 50%;
}

.OrdersIcons {
  float: right;
  font-size: 25px;
}

.wishicon {
  font-size: 20px;
  margin-left: 6px;
}

.pagebackground {
  background: #f7f7fa;
  /* border: 1px solid #dbdcde; */
}

/* ========================================SideBar  start================================== */
/*
@media screen and (max-width: 800px) {
  .sidebar {
    display: none;
  }
}
@media screen and (min-width: 800px) {
  .toggle-icon {
    display: none;
  }
}
.profile-circle{
  background-color: white;
  width: 40%;
  height: auto;
  border-radius: 50%;
  align-self: center;
  align-content: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
}
.profile-img{
  height: 100%;
  width: 100%;
  overflow: hidden;
  border: 3px solid grey;
  border-radius: 86%;
}
.user-name{
  align-self: center;
  text-align: center;
  color:"#f8f8fb"
}
.user-name .h5{
  color:"#f8f8fb"
}
.pro-sidebar {
  color: #adadad;
  height: 100%;
  width: 270px;
  min-width: 270px;
  text-align: left;
  transition: width, left, right, 0.3s;
  position: relative;
  margin-left: -23px;
  z-index: 0!important;
}
.sidebar-heading{
  font-size: 20px;
  color: #000000b5;
}
===============================Sidebar end===========================================
==========================Sidebar Address start =================================
.address-card{
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
}
.address-content{
  text-align: left;
}
.address-content p{
  font-size: 100%;
}
.address-icon-row{
  margin-bottom: -23px;
}
.address-box-profile {
  border: 1px solid #eeeeee;
  padding: 10px;
}
@media screen and (max-width: 760px) {
  .address-box-profile {
    height: 120%;
  }
}
.address-plus{
  text-align: center;
}
.address-icon{
  color:#495057;
}
.address-icon:hover{
  color:black;
} */
/* .address-modal{
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f6f6f6;
  border-radius: 0.4rem;
  outline: 0;
  margin-right: auto;
  margin-left: auto;
  align-self: center;
}
.react-responsive-modal-modal {
  max-width: 90%;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: #ffffff;
  box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
  margin: 1.2rem;
  padding: 1.2rem;
  position: relative;
  overflow-y: auto;
  margin-top: 9%;
}
@media screen and (max-width: 600px) {
  .react-responsive-modal-modal {
    margin-top: 15%;
  }
} */
/*============================== Address Modal end ===================================*/
/* =============================Help Center========================================
.nav-link-style.nav-link-light {
  color: rgba(255,255,255,0.65);
}
.fs-sm .border-light {
  border-color: rgba(255,255,255,0.12) !important;
}
.fs-sm .border-bottom {
  border-bottom: 1px solid #e3e9ef !important;
}
.nav-link-style.nav-link-light:hover {
  color: #fff;
}
.btn-outline-primary {
  color: #777f80;
  border-color: #777f80;
}
.btn-outline-primary:hover {
  background-color: #777f80;
  border-color: #777f80;
}
.handshake-icon{
  justify-content: center;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}
.bg-secondary {
  background-color: #f6f9fc !important;
}
.alert-info {
  color: #5fa1e5;
  background-color: #f0f7ff;
  border-color: #cbe4ff;
}
.alert {
  position: relative;
  padding: .75rem 1.125rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .4375rem;
}
.alert-info .alert-icon {
  background-color: rgba(105,179,254,0.1);
}
.alert-icon {
  display: flex;
  align-items: center;
  margin-top: -.75rem;
  margin-right: 1.125rem;
  margin-bottom: -.75rem;
  margin-left: -1.125rem;
  padding: .75em;
  border-top-left-radius: .4375rem;
  border-bottom-left-radius: .4375rem;
  font-size: 1.125rem;
}
.fs-sm {
  font-size: .875rem !important;
}
@media (min-width: 992px){
.offcanvas-collapse {
    display: block;
    position: static;
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
    width: 100% !important;
    height: auto !important;
    transform: none !important;
    background-color: transparent;
  }
}
.offcanvas {
    will-change: transform, box-shadow;
    transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1),box-shadow 0.3s ease;
    box-shadow: none;
    visibility: visible !important;
}
.border-end {
    border-right: 1px solid #e3e9ef !important;
}
@media (min-width: 992px){
  .offcanvas-collapse .offcanvas-header, .offcanvas-collapse .offcanvas-footer {
    display: none;
  }
}
.offcanvas-header, .offcanvas-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    padding: 1.25rem 1.5rem;
}
@media (min-width: 992px){
  .offcanvas-collapse .offcanvas-body {
    padding: 0;
    overflow: initial;
  }
}
.offcanvas-body {
  height: 100%;
}
.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}
.simplebar-track.simplebar-horizontal {
  height: .1875rem !important;
}
.offcanvas-body>.simplebar-track {
  display: block;
  background-color: transparent;
}
.simplebar-track.simplebar-vertical {
  width: .1875rem !important;
}
.offcanvas-body .simplebar-vertical {
  margin-right: .1875rem;
}
.offcanvas-body>.simplebar-track {
  display: block;
  background-color: transparent;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar {
  right: 0;
  width: .1875rem;
}
.simplebar-track .simplebar-scrollbar {
  border-radius: .09375rem;
  background-color: #aeb4be;
}
.widget-list-item {
  margin-bottom: .5rem;
}
.widget-list-link {
  display: block;
  transition: color 0.25s ease-in-out;
  color: #4b566b;
  font-size: .875rem;
  font-weight: normal;
}
.widget-list-link:hover {
  color: #777f80;
  font-size: .880rem;
}
.nav-link-style {
  color: #4b566b;
} */
.nav {
  background: white !important;
  color: #fff;
  z-index: 99;
}

.topnav .topnav-menu {
  margin: 0;
  padding: 0;
  background-color: white;
}

nav {
  background: #f8f9fa;
  padding: 20px;
  display: flex;
  justify-content: center;
  color: #fff;
  position: relative;
  z-index: 99;
}

.page-title-box {
  padding-bottom: 5px !important;
}

body[data-topbar="dark"] .header-item:hover {
  color: #e9ecef !important;
}

.btn:hover {
  color: #e9ecef !important;
}

li.nav-item.dropdown {
  /* margin-left: -2px!important; */
  /* border-left: 1px solid black; */
  padding-left: 5px !important;
}

.topnav .navbar-nav .nav-link {
  padding: 5px;
}

.CompanyName {
  color: white !important;
  padding-left: 2rem;
  /* font-family: initial; */
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 25px !important;
}

.Bechofy-count {
  border: 1px solid grey;
  /* padding: 6px; */
  /* border-radius: 50%; */
  width: 2rem;
  height: 2rem;
  background: black;
  color: white !important;
  text-align: center;
  width: 55px;
  height: 25px;
  border-radius: 10%;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
}

.arrow-down:after {
  margin-left: 5px;
}

table.table.table-bordered.table-hover.table-striped.dataTable thead {
  text-align: center !important;
  background: #eff2f7 !important;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #ced4da !important;
}

/* #ced4da */
/* =====Customer cart  */
.product-list {
  width: 200px;
  margin-top: 5px;
}

.product-list .product-card {
  background: white;
  padding: 10px;
  border: navajowhite;
  border-radius: 1rem !important;
}

.product-list .card-body {
  text-align: center;
  padding: 0;
  padding: 15px 0px;
}

.wishlist {
  color: red;
}

.product-list .product-card .product-name {
  color: #495057;
  margin-bottom: 10px;
  font-size: 18px;
}

.product-list .product-card .price {
  font-size: 22px;
  color: #495057;
}

.product-list .product-card .rating {
  color: #495057;
}

.product-list .product-card .rating a {
  color: #ffe000;
  font-size: 12px;
}

.product-list .product-card .card-top {
  margin-top: 4px;
  margin-bottom: 10px;
}

.card-img-top {
  width: 150px;
  height: 140px;
  margin: auto;
  border: 1px solid rgb(133, 126, 126);
  border-radius: 50%;
}

.product-list .product-card .rating label {
  margin-left: 5px;
}

.product-list .product-card .discount {
  margin: 0;
}

.product-list .product-card .discount span {
  padding: 4px 5px;
  background: #9ce7ff;
  color: #000;
}

.companyLogo {
  margin-right: 20px;
  height: 65px;
  width: 65px;
  float: right;
  margin-bottom: 10px;
}

.page-title-box.d-flex.align-items-center.justify-content-between {
  float: left !important;
}

.btnicons {
  width: 20px;
}

.ProfilesIcon img {
  width: 40px;
}

table.table.table-bordered.table-hover.table-striped.dataTable tbody {
  text-align: center;
}

.brandimages {
  position: relative;
  width: 135px !important;
  height: 130px !important;
  overflow: hidden;
}

.brandimages:before {
  opacity: 0;
  width: 135px !important;
  height: 130px !important;
  transition: 0.5s ease;
  background-color: #fff;
  font-size: 65px !important;
  padding-top: 30px !important;
}

.BrandStatus {
  position: relative;
  top: 10px;
}

.deleteicons {
  margin-top: 15px;
  float: right;
  margin-left: 5px;
}

.FilterCardBtn {
  position: relative;
  bottom: 0px;
  color: white;
  margin-left: 0px;
  margin-right: 4px;
}

.FilterCardBtn2 {
  position: relative;
  bottom: 0px;
}

.FilterCardBtn3 {
  position: relative;
  bottom: 0px;
  color: white;
  margin-left: 0px;
  margin-right: 15px;
}

.FilterCardBtn4 {
  position: relative;
  bottom: 0px;
}

.AddNewBtn {
  float: right;
  margin-top: 7px;
  background: white;
  color: grey;
  margin-right: 5px;
}

button.btn.align-items-center.btn.Bechofy-btn.AddNewBtn:hover {
  color: grey !important;
}

.ClassificationtextLeft {
  float: left;
}

.ClassificationtextRight {
  float: right;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #ffff;
}

/* ======Search bar css */
.search-result ul .list-group-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-result .card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.25rem;
  height: 150px;
  overflow-y: auto;
  overflow-x: none;
}

.search-result .list-group-item h5 {
  font-size: 12px;
}

.search-result .card-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.search-result .card-body::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.search-result .card-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #6b6b6b;
}

.search-result {
  position: absolute;
  top: 3rem;
  top: 3rem;
  left: 0;
  right: 0;
}

/* ======BreadCrumbs */
ul.breadcrumbs {
  padding: 38px 16px 8px 16px;
  list-style: none;
  /* background-color: #eee; */
}

ul.breadcrumbs li {
  display: inline;
  /* font-size: 15px; */
}

ul.breadcrumbs li+li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
}

ul.breadcrumbs li a {
  color: black;
  text-decoration: none;
}

ul.breadcrumbs li a:hover {
  color: black;
  text-decoration: underline;
}

.bg-image {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-image: url("../src/assets/images/dashboardimages/blurbackground.jpeg");
}

.table-bordered th,
.table-bordered td .actionuser {
  border: none !important;
}

/* ===============Price List================ */
.price-card {
  border: none;
  -webkit-box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
}

/* ================stepper================= */
.MuiSvgIcon-root {
  fill: currentColor;
  width: 4em;
  height: 28px;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.stepper-heading {
  font-weight: 100;
  font-family: "Material Design Icons";
}

.download-image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.download-container:hover .image {
  opacity: 0.3;
}

.download-container:hover .middle {
  opacity: 1;
}

.text {
  background-color: #04aa6d;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}

.download-container {
  position: relative;
}

.downloadcsv-button {
  color: #fff !important;
  background-color: #777f80 !important;
  border-color: #777f80 !important;
}

/* .downloadcsv-button:hover {
      color: #777f80;
      background-color: white !important;
      border-color: black !important;
    } */
.AccountExpiry {
  position: absolute;
  top: 69%;
  right: 3%;
  transform: translateY(-50%);
}

tbody tr .centered {
  text-align: center;
}

tbody tr .leftAlign {
  text-align: left;
}

tbody tr .rightAlign {
  text-align: right;
}

thead tr .centered {
  text-align: center;
}

thead tr .leftAlign {
  text-align: left;
}

thead tr .rightAlign {
  text-align: right;
}

.noDataFound {
  text-align: center;
  top: 50%;
  right: 41%;
  position: absolute;
  font-size: 20px;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 10px 107px !important;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: #777f80 !important;
  color: #fff !important;
  border-radius: 5px 5px 0 0;
}

.coatingdesc {
  line-height: 1.5em;
  height: 6rem;
  /* height is 2x line-height, so two lines will display */
  overflow: hidden;
  text-align: justify;
  padding-left: 25px;
}

.btn:hover {
  color: none !important;
}

.NoItemFoundInTab {
  height: 200px;
  text-align: center;
  padding: 87px;
  /* top: 50%; */
  /* right: 50%; */
  /* position: absolute; */
  font-size: 20px;
}

.DownloadPdf .btn:hover {
  color: unset !important;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: auto;
  right: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0px;
  right: 25px;
  font-size: 36px;
}

.add-edit-product-images {
  position: absolute;
  top: 5px;
  right: 15px;
  z-index: 1;
}

.add-edit-product-images .btnicons {
  width: 25px;
}

.product-image-slider .slick-prev {
  left: 0px;
  z-index: 999;
}

.product-image-slider .slick-next {
  right: 0px;
}

.drawer.item-management {
  width: 70% !important;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.form-group label {
  color: #000;
}

.black-text {
  color: #000;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

.content-draw {
  padding: 0 1.5rem 0 1.5rem;
}

/* ********Login logo********* */
.login-logo {
  height: 60px !important;
  width: 60px !important;
}

.drawer {
  width: 450px !important;
}

.serialize-side-drawer {
  max-height: 800px;
  overflow-y: scroll;
}

.variant-list-image {
  width: 100px;
}

.billing-pdf p {
  padding: 2px 0;
  margin: 0;
}

.invoice-pdf-size {
  max-width: 190mm !important;
  width: 100% !important;
  margin: auto !important;
}

.dispatch-size {
  max-width: 100% !important;
  width: 100% !important;
  margin: auto !important;
}

.blog-description {
  overflow-y: auto !important;
  height: 130px !important;
}

.blog-title {
  height: 50px !important;
}

.blog-image-main {
  height: 250px;
  object-fit: cover;
}

.text-align-right {
  text-align: right;
}

.offerlistcard {
  font-weight: 600 !important;
}

.offerlist-dynamicdataname {
  width: 60%;
  text-align: start;
}

.offerlist-dynamicdata {
  width: 40%;
  text-align: start;
}

.offer-box-bg {
  border: 1px solid #f8f8fb !important;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  background: white;
  padding: 20px !important;
}

.offerlist-column {
  font-weight: 600 !important;
}

.our-product-collection-image-upload {
  border: 1px solid;
  border-radius: 5px;
  background-color: #fff;
  height: 240px;
}

.card-color-picker {
  border: 1px solid;
  border-radius: 2px;
  height: 30px;
  width: 30px;
  display: inline-block;
}

.cropper-modal {
  background-color: #00000012 !important;
  opacity: 1 !important;
}

.cropper-img-preview {
  width: 30% !important;
  height: 30% !important;
}

.inactive-box {
  width: 32%;
  aspect-ratio: 1/1;
  margin-bottom: 2rem;
  position: relative;
}

.inactive-box input {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
}

.inactive-box img {
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 5px;
}

.sidebar-inactive {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between !important;
  padding: 1rem !important;
  overflow-y: auto;
}

.selectimage-sidebar {
  width: 1000px !important;
}

.seotablescroll {
  overflow-x: scroll;
}

.cod-box {
  /* position: absolute;
  top: 0;
  right: 0; */
  padding: 2px 5px;
  border-radius: 5px;
  background: #52c41a;
  color: #fff;
  width: fit-content;
  font-size: 0.6rem;
  margin-left: 10px;
}

.payment-mod-btns .nav-pills .nav-link {
  color: #000;
}

.payment-mod-btns .nav-item img {
  width: 18px;
  height: 18px;
  margin-right: 5px;
  object-fit: contain;
}

.payment-mod-btns .nav-item .upi-icon {
  width: 25px;
  height: 20px;
  margin: 0;
}

.payment-mod-btns .nav-pills .nav-link.active {
  color: #fff !important;
  background: #000;
}

.payment-mod-btns .nav-pills .nav-link.active img {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}