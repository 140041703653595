.input-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8rem;
}

.input-box .error-msg {
  font-size: 0.8rem;
  color: #ff0000;
  margin-top: 3px;
}

.input-box label {
  margin-bottom: 7px;
  font-weight: 500;
  font-size: 0.9rem;
}

.input-box .ant-radio-wrapper {
  margin-bottom: 0 !important;
  margin-top: 10px;
}

.input-box .ant-radio-wrapper>span {
  display: flex !important;
  align-items: center !important;
}

.input-box .ant-radio-wrapper>span svg {
  margin-right: 5px;
}

.input-box .ant-select-single {
  width: 100% !important;
  height: 40px !important;
}

.biilingadd-box {
  border: 1px solid #e3e3e3;
  padding: 10px;
  border-radius: 5px;
  margin-top: 1rem;
}

.biilingadd-box label {
  font-size: 1.2rem;
  font-weight: 500;
}

.item-list-section h5 {
  margin-bottom: 1rem;
}

.item-list-section .item-card {
  display: flex;
  padding: 10px;
  padding-top: 0;
  margin-bottom: 1rem;
  position: relative;
  border: 1px solid #e3e3e3;
  padding: 10px;
  border-radius: 5px;
}

.item-list-section .item-card p {
  margin-bottom: 0;
}

.item-card .remove-btn {
  color: #ff0000;
  font-size: 0.9rem;
  cursor: pointer;
}

.item-list-section .item-card:last-child {
  margin-bottom: 0;
}

.item-list-section .item-card .image {
  width: 70px;
  aspect-ratio: 1/1;
  margin-right: 1rem;
}

.item-list-section .item-card .image img {
  height: 100%;
}

.item-list-section .item-card .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 0 !important;
  padding: 0;
}

.item-list-section .item-card .content .name,
.item-list-section .item-card .content .quantity,
.item-list-section .item-card .content .price {
  font-weight: 500;
  margin-bottom: 5px;
}

.total-price-card {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  padding: 10px;
  border-radius: 5px;
}

.total-price-card .card-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 0.9rem;
}

.total-price-card .card-box p {
  font-weight: 500;
}

.total-price-card .total-price {
  font-weight: 500;
  font-size: 1rem !important;
  margin-top: 14px;
  padding-top: 5px;
  border-top: 1px solid #e3e3e3;
}

.input-box .ant-radio-group-solid .ant-radio-button-wrapper-checked,
.input-box .ant-radio-group-solid .ant-radio-button-wrapper-checked:hover {
  background-color: #000 !important;
  border-color: #000 !important;
  color: #000 !important;
}

.input-box .ant-radio-button-wrapper-checked::before,
.input-box .ant-radio-button-wrapper-checked:hover::before {
  background-color: #000 !important;
}

.product-card {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  display: flex;
  height: 100%;
}

.product-card .image {
  width: 150px;
  aspect-ratio: 1/1;
  margin-right: 10px;
  height: auto;
}

.product-card .image img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.product-card .content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 0 !important;
}

.product-card .content p {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 1.1rem;
}

.product-card .content .add-cart {
  margin-top: 1rem;
}

.product-card .content .add-cart .add-btn,
.product-card .content .add-cart .qut {
  padding: 5px 10px;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.product-card .content .add-cart .qut {
  justify-content: space-between;
  background: transparent;
  border: 1px solid #000;
  color: #000;
  padding: 0;
  overflow: hidden;
}

.product-card .content .add-cart .qut span {
  font-size: 1.3rem;
  height: 100%;
  background: #000;
  padding: 0 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-add-type-box {
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #e3e3e3;
  width: fit-content;
}

.select-add-type-btn {
  padding: 5px 10px;
  background-color: transparent;
  border: none;
  color: #000;
  border-radius: 0 !important;
  cursor: pointer;
}

.select-add-type-btn:nth-child(2) {
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
}

.select-add-type-btn.active {
  background-color: #000;
  color: #fff;
}

.theme-btn {
  padding: 5px 10px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;

}

.city-search {
  position: absolute;
  background-color: #000;
  text-align: center;
  padding: 8px 9px;
  border-radius: 10px;
  right: -124px;
  top: 30px;
  font-size: 12px;
  text-align: left;
  color: #fff;
}

.city-search:after {
  content: "";
  position: absolute;
  top: 45%;
  left: -10%;
  margin-top: -4px;
  border: 5px solid transparent;
  border-right-color: #251d34;
}