.bg-footer {
  background-color: var(--color-theme);
}
.footer-ul {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
}
.footer-ul .footer-ul-link a {
  color: var(--color-white);
  text-decoration: none;
  transition: all ease-in-out 0.3s;
}
.footer-ul .footer-ul-link a:hover {
  color: var(--color-theme-secondary);
  text-decoration: none;
}
.social-ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  gap: 10px;
  margin: 0;
  padding: 0;
}
.social-ul {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.social-ul .social-ul-link {
  border: 2px solid var(--color-theme);
  width: 45px;
  height: 45px;
  color: var(--color-white);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-theme);
  transition: all ease-in-out 0.3s;
  border: 1px solid var(--color-white);
}
.social-ul .social-ul-link svg {
  color: var(--color-white);
  font-size: 22px;
}
.social-ul .social-ul-link:hover {
  background-color: var(--color-theme);
}
.social-ul .social-ul-link:hover svg {
  color: var(--color-white);
}
.logo-footer {
  width: 220px;
  height: auto;
}
@media screen and (max-width: 768px) {
  .footer-ul {
    margin-top: 10px;
  }
  .footer-ul .footer-ul-link a {
    font-size: 12px;
  }
}
