.cms-acoordian{
    border-radius: 0.25rem !important;
}
.cms-acoordian .accordion-button{
    background: none !important;
    border: none !important;
    width: 100%;
}
.arrow{
    transform: rotateZ(180deg);
}
.arrowanticlock{
    transform: rotateZ(-360deg);
}