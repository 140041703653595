.image-upload.center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .image-upload__preview {
    width: 16rem;
    height: 16rem;
    border: 1px solid #F7C4D3;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;
    /* margin-left: 10px; */
  }
  
  .image-upload__preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .closeicon-class{
    font-size: 18px;
    color: #777f80;
    float: right; 
    top: 7px;
    position: relative;
    left: 16px;
    cursor: pointer
  }