// 
// crypto.scss
//
@media (min-width: 576px){
    .currency-value{
        position: relative;
        &:after{
            content: "\F04E1";
            font-family: "Material Design Icons";
            font-size: 24px;
            position: absolute;
            width: 45px;
            height: 45px;
            line-height: 45px;
            border-radius: 50%;
            text-align: center;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: $primary;
            color: $white;
            z-index: 9;
            right: -34px;
        }
    }
}
.crypto-buy-sell-nav-content{
    border: 2px solid $gray-300;
    border-top: 0;
}
// KYC Application
.kyc-doc-verification{
    .dropzone{
        min-height: 180px;
        .dz-message{
            margin: 24px 0px;
        }
    }
}
/******************
    Ico Landing
*******************/
.section{
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
    &.bg-white{
        background-color: $card-bg !important;
    }
}
.small-title{
    color: $gray-600;
    margin-bottom: 8px;
}
// Navigation
.navigation {
    padding: 0 16px;
    width: 100%;
    z-index: 999;
    margin-bottom: 0px;
    transition: all 0.5s ease-in-out;
    border-bottom: 1px solid rgba($white, 0.1);
    @media (max-width: 991.98px) {
        background-color: $topnav-bg;
    }
    
    .navbar-logo{
        line-height: 70px;
        transition: all 0.4s;
        .logo-dark{
            display: none;
            @media (max-width: 991.98px) {
                display: block;
            }
        }
        .logo-light{
            display: block;
            @media (max-width: 991.98px) {
                display: none;
            }
        }
    }
    
    .navbar-nav{
        .nav-item{
            .nav-link{
                color: rgba($white, 0.6);
                line-height: 58px;
                padding: 6px 16px;
                font-weight: $font-weight-medium;
                transition: all 0.4s;
                @media (max-width: 991.98px) {
                    color: $header-item-color;
                }
                &:hover, &.active{
                    color:  rgba($white, 0.9);
                    @media (max-width: 991.98px) {
                        color: $primary;
                    }
                }
                @media (max-width: 991.98px) {
                    line-height: 28px !important;
                }
            }
        }
    }
    &.nav-sticky{
        background-color: $topnav-bg;
        box-shadow: $box-shadow;
        .navbar-logo{
            line-height: 60px;
            .logo-dark{
                display: $display-block;
            }
            .logo-light{
                display: $display-none;
            }
        }
        .navbar-nav {
            .nav-item {
                .nav-link{
                    line-height: 48px;
                    color: $header-item-color;
                    &:hover, &.active{
                        color: $primary;
                    }
                }
            }
        }
    }
}
.bg-overlay{
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    opacity: 0.7;
    background-color: $black;
}
.hero-section{
    padding-top: 220px;
    padding-bottom: 190px;
    &.bg-ico-hero{
        background-image: url("../../../images/crypto/bg-ico-hero.jpg");
        background-size: cover;
        background-position: top;
    }
    @media (max-width: 575.98px) {
        padding-top: 140px;
        padding-bottom: 80px;
    }
    .hero-title{
        font-size: 42px;
        @media (max-width: 575.98px) {
            font-size: 26px;
        }
    }
    .ico-countdown{
        font-size: 22px;
        margin-right: -12px;
        margin-left: -12px;
        @media (max-width: 575.98px) {
            display: block;
        }
        .coming-box{
            margin-right: 12px;
            margin-left: 12px;
            border: 1px solid $border-color;
            border-radius: 4px;
            padding: 8px;
            background-color: $card-bg;
            @media (max-width: 575.98px) {
                display: inline-block;
                width: 40%;
                margin-bottom: 24px;
            }
            span{
                background-color: $light;
                font-size: 12px;
                padding: 4px;
                margin-top: 8px;
            }
        }
    }
    .softcap-progress{
        overflow: visible;
        .progress-bar{
            overflow: visible;
        }
        .progress-label{
            position: relative;
            text-align: right;
            color: $body-color;
            bottom: 20px;
            font-size: 12px;
            font-weight: $font-weight-medium;
        }
    }
}
// currency price
.currency-price{
    position: relative;
    bottom: 40px;
}
// Clients
.client-images {
    img {
        max-height: 34px;
        width: auto !important;
        margin: 12px auto;
        opacity: 0.7;
        transition: all 0.4s;
    }
}
// Features
.features-number{
    opacity: 0.1;
}
// Team
.team-box{
    .team-social-links{
        a{
            color: $body-color;
            font-size: 14px;
        }
    }
}
// Blog
.blog-box{
    .blog-badge{
        position: absolute;
        top: 12px;
        right: 12px;
    }
}
// landing footer
.landing-footer{
    padding: 80px 0 40px;
    background-color: $sidebar-dark-bg;
    color: rgba($white, 0.5);
    .footer-list-title{
        color: rgba($white, 0.9);
    }
    .footer-list-menu {
        li {
            a {
                display: block;
                color: rgba($white, 0.5);
                margin-bottom: 14px;
                transition: all 0.4s;
                &:hover{
                    color: rgba($white, 0.8);
                }
            }
        }
    }
    .blog-post{
        .post{
            display: block;
            color: rgba($white, 0.5);
            padding: 16px 0px;
            border-bottom: 1px solid rgba($white, 0.1);
            .post-title{
                color: rgba($white, 0.8);
                font-size: 14px;
            }
            &:first-of-type{
                padding-top: 0;
            }
            &:last-of-type{
                padding-bottom: 0;
                border-bottom: 0;
            }
        }
    }
    .footer-border{
        border-color: rgba($white, 0.1);
    }
}
